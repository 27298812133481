.ActiveLogo  {
  position: relative;
  width: 83px;
  height: 85px;
}

.ActiveLogoContainer {
  position: absolute;
  width: 83px;
  height: 85px;
}

.EyeSocket {
  position: absolute;
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 10px;
}

.EyeHole {
  position: absolute;
  width: 8px;
  height: 8px;
  background: black;
  border-radius: 4px;
  transition: top 0.4s, left 0.4s;
}