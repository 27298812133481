.ConnectButton {
  background-image: linear-gradient(180deg, #7468FF 0%, #5647FF 100%);
  border-radius: 8px;
  font-family: 'Cairo';
  font-weight: 500;
  font-size: 24px;
  color: #FFFFFF;
  letter-spacing: 1.85px;
  text-align: center;
  width: 250px;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.ConnectButton:hover {
  background-image: linear-gradient(180deg, #5141FF 0%, #2E22B6 100%);
  border-radius: 8px;
}

.ConnectButton:active {
  background-image: linear-gradient(180deg, #110D40 0%, #1F0BFF 100%);
  border-radius: 8px;
}