.AppConnectBar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.AppConnectContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  flex-wrap: wrap;
  cursor: pointer;
}

.AppConnectContainer > * {
  margin: 30px;
}

.AppInteractiveNFTs {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Cairo';
  font-weight: 700;
  font-size: 24px;
  color: #56543D;
  padding-bottom: 15px;
  padding: 15px;
  box-sizing: border-box;
}

.AppInteractive {
  color: #7D2510;
  margin-left: 5px;
  margin-right: 5px;
}