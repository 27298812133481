.WishboneContainer {
  border: 2px solid #7D2510;
  border-radius: 8px;
  padding: 15px;
  display: inline-block;
  position: relative;
}

.WishboneContainerTitle {
  font-family: 'Cairo';
  font-weight: 500;
  font-size: 24px;
  color: #7D2510; 
}

.WishboneContainerDescription {
  font-family: 'Cairo';
  font-weight: 500;
  font-size: 16px;
  width: 250px;
  height: 90px;
  color: #56543D; 
}

.CountDownTimer {
  position: absolute;
  top: -15px;
  left:  -15px;
  background: #7D2510;
  padding: 3px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  font-family: 'Cairo';
  font-weight: 500;
  font-size: 20px;
  color: #FFFFFF; 
}