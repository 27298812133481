.ProfileView {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center
}

.ProfileViewWelcome {
  font-family: 'Cairo';
  font-weight: 600;
  font-size: 16px;
  color: #56543D;
}

.ProfileViewAddress {
  font-family: 'Cairo';
  font-weight: 700;
  font-size: 16px;
  color: #7D2510;
}

.ProfileViewLogout {
  font-family: 'Cairo';
  font-weight: 600;
  font-size: 16px;
  color: #FFFFFF;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  margin-top: 10px;
  letter-spacing: 1.55px;
}

.ProfileViewLogout {
  background-image: linear-gradient(180deg, #895637 0%, #482B17 100%);
  border-radius: 4px;
}

.ProfileViewLogout:hover {
  background-image: linear-gradient(180deg, #573723 0%, #0F0905 100%);
  border-radius: 4px;
}

.ProfileViewLogout:active {
  background-image: linear-gradient(180deg, #24170E 0%, #0F0905 100%);
  border-radius: 4px;
}